import { memo } from 'react';
import { InternalLink } from '../../internal-link/internal-link';
import { BulletDiamondIcon } from '../../svg-icons/bullet-diamond';
import styles from './card-list-header.module.css';

export type CardListHeaderProps = {
  title: string;
  id?: string;
  className?: string;
  link?: string;
  icon?: React.ReactNode;
  size?: 'medium' | 'large';
};

export function CardListHeader({
  id,
  title,
  link,
  className = '',
  icon = <BulletDiamondIcon />,
  size = 'medium',
}: CardListHeaderProps) {
  return (
    <h2 id={id} className={`${styles.root} ${styles[`${size}Size`] || ''} ${className}`}>
      {icon}
      {link ? <InternalLink href={link}>{title}</InternalLink> : <span>{title}</span>}
    </h2>
  );
}

export const CardListHeaderMemo = memo(CardListHeader);
