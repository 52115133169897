import { useCallback, useEffect, useRef, useId } from 'react';
import { useStore } from '../providers/store-provider';
import {
  setCardDeactivationTimeout,
  clearCardDeactivationTimeout,
} from '../utils/card-deactivation-timeout';
import { navSystem } from '../utils/nav-system';

export function useCard(onActivate?: () => void, onDeactivate?: () => void) {
  const cardId = useId();
  const isActive = useStore((state) => state.activeCardId === cardId);
  const setActiveCardId = useStore((state) => state.setActiveCardId);

  // Keep some props and state in ref so we can use them in useEffect without
  // passing them as dependencies.
  const stateRef = useRef({ isActive, isUnmounted: false, onActivate, onDeactivate });
  stateRef.current.isActive = isActive;
  stateRef.current.isUnmounted = false;
  stateRef.current.onActivate = onActivate;
  stateRef.current.onDeactivate = onDeactivate;

  const activateCard = useCallback(() => {
    clearCardDeactivationTimeout();
    if (!isActive) setActiveCardId(cardId);
  }, [cardId, isActive, setActiveCardId]);

  const deactivateCard = useCallback(() => {
    setCardDeactivationTimeout(() => {
      setActiveCardId(null);
    });
  }, [setActiveCardId]);

  const onPointerEnter = useCallback(() => {
    if (navSystem.getNavDevice() !== 'keyboard') {
      activateCard();
    }
  }, [activateCard]);

  const onPointerLeave = useCallback(() => {
    if (navSystem.getNavDevice() !== 'keyboard') {
      deactivateCard();
    }
  }, [deactivateCard]);

  const onFocus = useCallback(() => {
    activateCard();
  }, [activateCard]);

  const onBlur = useCallback(() => {
    deactivateCard();
  }, [deactivateCard]);

  // Deactivate card instantly when it unmounts IF it is active. This is needed
  // because pointerleave event doesn't fire when a hovered element is removed.
  useEffect(() => {
    if (typeof window === 'undefined') return;

    return () => {
      const { isActive, isUnmounted, onDeactivate } = stateRef.current;
      if (!isUnmounted && isActive) {
        stateRef.current.isUnmounted = true;
        clearCardDeactivationTimeout();
        setActiveCardId(null);
        onDeactivate?.();
      }
    };
  }, [setActiveCardId]);

  // Call onActivate and onDeactivate when isActive changes.
  useEffect(() => {
    if (typeof window === 'undefined') return;

    const { isUnmounted, onActivate, onDeactivate } = stateRef.current;

    if (isUnmounted) return;

    if (isActive) {
      onActivate?.();
    } else {
      onDeactivate?.();
    }
  }, [isActive]);

  return {
    cardId,
    isActive,
    setActiveCardId,
    activateCard,
    deactivateCard,
    onPointerEnter,
    onPointerLeave,
    onFocus,
    onBlur,
  };
}
