import { useCallback, PointerEvent, FocusEvent } from 'react';
import { useRouter } from 'next/router';
import { useApolloClient } from '@apollo/client';
import { GetItemDetail } from '../../graphql-queries';
import { AnchorButton, AnchorButtonProps } from '../buttons/button/button';

export type DetailLinkProps = Omit<AnchorButtonProps, 'href'> & {
  itemId: string;
};

export function DetailLink({
  itemId,
  children,
  onFocus,
  onPointerEnter,
  ...restProps
}: DetailLinkProps) {
  const router = useRouter();
  const href = {
    pathname: router.pathname,
    query: { ...router.query, info: itemId },
  };
  const apolloClient = useApolloClient();

  const prefetchData = useCallback(() => {
    apolloClient
      .query({
        query: GetItemDetail,
        variables: { itemId },
      })
      .catch((e) => {
        console.error(`Failed to prefetch game detail data: ${itemId}`);
        console.error(e);
      });
  }, [itemId, apolloClient]);

  const handlePointerEnter = useCallback(
    (e: PointerEvent<HTMLAnchorElement>) => {
      prefetchData();
      onPointerEnter?.(e);
    },
    [prefetchData, onPointerEnter],
  );

  const handleFocus = useCallback(
    (e: FocusEvent<HTMLAnchorElement, Element>) => {
      prefetchData();
      onFocus?.(e);
    },
    [prefetchData, onFocus],
  );

  return (
    <AnchorButton
      href={href}
      prefetch={false}
      shallow
      onPointerEnter={handlePointerEnter}
      onFocus={handleFocus}
      {...restProps}
    >
      {children}
    </AnchorButton>
  );
}
