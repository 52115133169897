import { memo, useRef, useState, useEffect } from 'react';
import { useStore } from '../../../providers/store-provider';
import styles from './card-video.module.css';

export type CardVideoProps = {
  isActive: boolean;
  src?: string;
  className?: string;
  playDelay?: number;
};

export function CardVideo({ isActive, src = '', className = '', playDelay = 400 }: CardVideoProps) {
  const videoRef = useRef<HTMLVideoElement>(null);
  const currentTimeRef = useRef<number>(0);
  const playTimeoutRef = useRef<number>(-1);
  const playDelayRef = useRef(playDelay);
  const [isVideoPlaying, setVideoPlaying] = useState(false);
  const debugDisableVideos = useStore(({ debug }) => debug.disableVideos);

  // Keep track of the current play delay. We want to use this value within
  // effect without providing it as a dependency, because we don't want to
  // restart the video when the delay changes.
  playDelayRef.current = playDelay;

  // Reset currentTimeRef when the videoUrl changes.
  useEffect(() => {
    currentTimeRef.current = 0;
  }, [src]);

  // Play video once it's ready.
  useEffect(() => {
    // No playing videos on the server.
    if (typeof window === 'undefined') return;

    if (!src || !isActive || debugDisableVideos !== false) return;

    const videoElem = videoRef.current;
    if (!videoElem) return;

    let didUnmount = false;

    const playVideo = () => {
      playTimeoutRef.current = window.setTimeout(() => {
        videoElem
          .play()
          .then(() => {
            if (didUnmount) return;
            setVideoPlaying(true);
          })
          .catch((e) => {
            if (didUnmount) return;
            console.error(e);
          });
      }, playDelayRef.current);
    };

    // Play video if it's ready.
    if (videoElem.readyState === 4) {
      videoElem.currentTime = currentTimeRef.current;
      playVideo();
    }
    // Otherwise, let's wait for the video to be ready.
    else {
      videoElem.load();
      videoElem.currentTime = currentTimeRef.current;
      videoElem.addEventListener('canplaythrough', playVideo, { once: true });
    }

    return () => {
      const { currentTime, ended, paused } = videoElem;
      didUnmount = true;

      window.clearTimeout(playTimeoutRef.current);
      videoElem.removeEventListener('canplaythrough', playVideo);

      // Update currentTimeRef.
      currentTimeRef.current = ended ? 0 : currentTime;

      // Pause video if it's playing.
      if (!paused) {
        videoElem.pause();
        setVideoPlaying(false);
      }
    };
  }, [src, isActive, debugDisableVideos]);

  return src && !debugDisableVideos ? (
    <video
      key={src}
      ref={videoRef}
      className={`${styles.root} ${className}`}
      src={src}
      muted={true}
      aria-hidden="true"
      preload="none"
      onEnded={() => {
        setVideoPlaying(false);
      }}
      data-playing={isVideoPlaying}
    ></video>
  ) : null;
}

export const CardVideoMemo = memo(CardVideo);
