import { memo, useState, useEffect, useMemo, useRef, useCallback } from 'react';
import Image from 'next/image';
import { CardVideoMemo } from '../card-video/card-video';
import { InternalLink } from '../../internal-link/internal-link';
import { useCard } from '../../../hooks/use-card';
import { useStore, useStoreAPI } from '../../../providers/store-provider';
import {
  ARROW_NAV_TARGET_CLASS,
  ARROW_NAV_RECT_ATTRIBUTE,
  FOCUS_SCROLL_CONTAINER_ATTRIBUTE,
  FOCUS_SCROLL_CONTAINER_TARGET_ATTRIBUTE,
} from '../../../utils/nav-system';
import { PopupCardPopoverMemo } from './popup-card-popover';
import styles from './popup-card.module.css';

const HIDE_ANIMATION_DURATION = 200;
const INFO_MODAL_CLOSE_TIMEOUT = 200;

export type PopupCardProps = {
  // Item data.
  id: string;
  type: string;
  name: string;
  launchUrl: string;
  imageUrl: string;
  videoUrl?: string;
  shortDescription?: string;
  categories?: { id: string; name: string }[];
  // Other props.
  size?: 'medium' | 'large';
  orientation?: 'landscape' | 'portrait';
  focusScrollContainerAxis?: 'x' | 'y' | '';
  rank?: number;
  onActivate?: () => void;
  onDeactivate?: () => void;
};

export function PopupCard({
  size = 'medium',
  orientation = 'landscape',
  focusScrollContainerAxis = '',
  rank,
  onActivate,
  onDeactivate,
  ...item
}: PopupCardProps) {
  const { cardId, isActive, onPointerEnter, onPointerLeave, onFocus, onBlur } = useCard(
    onActivate,
    onDeactivate,
  );
  const [isPopoverVisible, setPopoverVisible] = useState(isActive);
  const [isInfoModalVisible, setInfoModalVisible] = useState(false);
  const store = useStoreAPI();
  const addRecentlyPlayedId = useStore((state) => state.addRecentlyPlayedId);
  const rankString = useMemo(() => {
    return typeof rank === 'number' ? rank.toString().padStart(2, '0') : undefined;
  }, [rank]);
  const infoModalCloseTimeoutRef = useRef<number>(-1);
  const showInfoModal = useCallback(() => {
    window.clearTimeout(infoModalCloseTimeoutRef.current);
    setInfoModalVisible(true);
  }, []);

  // Show the popover when the card becomes active. On hide, let's wait for the
  // duration of the popover's hide animation before setting the
  // isPopoverVisible state to false.
  useEffect(() => {
    if (typeof window === 'undefined') return;

    if (isActive) {
      setPopoverVisible(true);
      return;
    }

    let timeout = window.setTimeout(() => {
      setPopoverVisible(false);
    }, HIDE_ANIMATION_DURATION);

    return () => {
      window.clearTimeout(timeout);
    };
  }, [isActive]);

  // When info modal is opened track when it is closed again so we can sync
  // the card's isInfoModalVisible state. We need to keep the state alive a
  // bit longer so the modal can return the focus to the card.
  useEffect(() => {
    if (typeof window === 'undefined') return;

    if (!isInfoModalVisible) return;

    const unsubscribe = store.subscribe(
      (state) => state.isModalOpen,
      (isModalOpen, prevIsModalOpen) => {
        if (prevIsModalOpen && !isModalOpen) {
          window.clearTimeout(infoModalCloseTimeoutRef.current);
          infoModalCloseTimeoutRef.current = window.setTimeout(() => {
            setInfoModalVisible(false);
          }, INFO_MODAL_CLOSE_TIMEOUT + 50);
        }
      },
    );

    return () => {
      unsubscribe();
    };
  }, [isInfoModalVisible, store]);

  // Clear the info modal close timeout when the component is unmounted.
  useEffect(() => {
    return () => {
      window.clearTimeout(infoModalCloseTimeoutRef.current);
    };
  }, []);

  return (
    <div
      className={`${styles.root} ${styles[size + 'Size']} ${styles[orientation + 'Orientation']} ${isActive ? styles.isActive : ''} ${isPopoverVisible ? styles.isPopoverVisible : ''} ${isInfoModalVisible ? styles.isInfoModalVisible : ''}`}
      onPointerDown={onPointerEnter}
      onPointerUp={onPointerEnter}
      onPointerMove={onPointerEnter}
      onPointerEnter={onPointerEnter}
      onPointerLeave={onPointerLeave}
      onFocus={onFocus}
      onBlur={onBlur}
      data-card-id={cardId}
      data-card-popover-visible={isPopoverVisible ? 'true' : 'false'}
      {...{
        [FOCUS_SCROLL_CONTAINER_ATTRIBUTE]: focusScrollContainerAxis,
      }}
    >
      <div
        className={styles.scrollToTarget}
        {...{
          [FOCUS_SCROLL_CONTAINER_TARGET_ATTRIBUTE]: focusScrollContainerAxis,
        }}
      ></div>
      <div className={styles.title}>
        {rankString ? <span className={styles.titleRank}>{rankString}</span> : null}
        <span className={styles.titleText}>{item.name}</span>
      </div>
      <PopupCardPopoverMemo
        cardId={cardId}
        isCardActive={isActive}
        isVisible={isPopoverVisible}
        isInfoModalVisible={isInfoModalVisible}
        showInfoModal={showInfoModal}
        {...item}
      />
      <div className={styles.imageContainer}>
        <Image
          className={styles.image}
          src={item.imageUrl}
          alt={item.name}
          draggable={false}
          fill
          quality={85}
          sizes={
            size === 'large'
              ? '(max-width: 619px) 80vw,(max-width: 863px) 42vw,(max-width: 1351px) 30vw,(max-width: 1595px) 23vw,(max-width: 1839px) 18vw,(max-width: 2083px) 15vw, 13.5vw'
              : '(max-width: 619px) 80vw,(max-width: 863px) 42vw,(max-width: 1107px) 29vw,(max-width: 1351px) 22vw,(max-width: 1595px) 18vw,(max-width: 1839px) 15vw,(max-width: 2083px) 13vw, 12vw'
          }
        />
        <CardVideoMemo src={item.videoUrl} isActive={isActive} />
        <InternalLink
          className={`${styles.imageLink} ${ARROW_NAV_TARGET_CLASS}`}
          href={item.launchUrl}
          target="_blank"
          rel="noreferrer"
          onClick={() => item.type === 'game' && addRecentlyPlayedId(item.id)}
          tabIndex={isActive ? -1 : 0}
          draggable={false}
          {...{
            [ARROW_NAV_RECT_ATTRIBUTE]: `[data-card-id="${cardId}"]`,
          }}
        ></InternalLink>
      </div>
    </div>
  );
}

export const PopupCardMemo = memo(PopupCard);
