import { CSSProperties, memo } from 'react';
import styles from './spacer.module.css';

export type SpacerProps = {
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'viewport';
  multiplier?: number;
  children?: React.ReactNode;
};

export function Spacer({ size = 'md', multiplier = 1, children = null }: SpacerProps) {
  return (
    <div
      className={`${styles.root} ${styles[size + 'Size']}`}
      style={
        {
          ['--spacer--multiplier']: `${multiplier}`,
        } as CSSProperties
      }
    >
      {children}
    </div>
  );
}

export const SpacerMemo = memo(Spacer);
